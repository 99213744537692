import { defineStore } from 'pinia'
import { createGhgpMetricRequest, type StaticMetricResponse, type GhgpMetricRequestBody } from '@api/gri'
import type { AxiosError } from 'axios'
import { useSnackStore } from '@store/snackStore'

type GHGPMetricStoreData<T> = StoredData<T> & {
  possibleCombinations: Record<string, T[]>
  queryStrings: string[]
}

export const useGghpMetricsStore = defineStore('ghgpMetrics', {
  state: () =>
    ({
      data: [],
      current: null,
      loading: true,
      ready: false,
      statusCode: 0,
      statusMessage: '',
      possibleCombinations: {},
      queryStrings: [],
    }) as GHGPMetricStoreData<StaticMetricResponse>,
  getters: {
    isOK(state) {
      return state.statusCode >= 200 && state.statusCode < 300
    },
  },
  actions: {
    resetState() {
      this.data = []
      this.current = null
      this.loading = false
      this.ready = false
      this.statusCode = 0
      this.statusMessage = ''
    },

    async fetchMetrics(body: GhgpMetricRequestBody) {
      const snackBar = useSnackStore()
      const queryStr = JSON.stringify(body)
      try {
        if (this.possibleCombinations[queryStr] && this.possibleCombinations[queryStr].length) return
        const response = await createGhgpMetricRequest(body)
        this.possibleCombinations[queryStr] = response as StaticMetricResponse[]
      } catch (error) {
        const code = (error as AxiosError).response?.status
        if (code === 503) snackBar.error('metricTimeoutError')
        else snackBar.error('metricGenericError')
      }
    },

    async addMetricCombinations(key: string) {
      try {
        if (this.queryStrings.includes(key)) return
        this.queryStrings.push(key)
      } catch {
        console.log('error adding metric combinations')
      }
    },

    async fetchMetricDataAsync() {
      try {
        for (const key of this.queryStrings) {
          await this.fetchMetrics(JSON.parse(key))
        }
      } catch (error) {
        console.error('error fetching ghgp metrics', (error as AxiosError).response)
      }
    },
  },
})
