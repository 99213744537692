import type { TranslationFunctions } from '@i18n/i18n-types'
import type { Ref } from 'vue'
import type { TreeNode } from 'primevue/treenode'

export function linkDataForTasks(LL: Ref<TranslationFunctions>) {
  const linkData: TreeNode[] = [
    {
      label: LL.value.sideBar.dashboard(),
      data: 'performance',
      icon: 'dashboardIcon',
      children: [{ label: LL.value.taskModal.environmental(), value: 'performance-environmental' }],
    },
    // { label: LL.value.sideBar.report(), value: 'report', icon: 'reportingIcon' },
    {
      label: LL.value.sideBar.accounting(),
      value: 'measure',
      icon: 'accountingIcon',
      children: [
        { label: LL.value.taskModal.newActivityTitle(), value: 'measure-activities' },
        { label: LL.value.taskModal.activityLog(), value: 'measure-activity-history' },
        { label: LL.value.taskModal.uploadHistory(), value: 'measure-upload-history' },
      ],
    },
    {
      label: LL.value.sideBar.improvement(),
      value: 'improve',
      icon: 'improvementIcon',
      children: [
        { label: LL.value.taskModal.goals(), value: 'improve-goals' },
        { label: LL.value.taskModal.measures(), value: 'improve-measures' },
      ],
    },
    {
      label: LL.value.sideBar.configuration(),
      value: 'configure',
      icon: 'configurationIcon',
      children: [
        { label: LL.value.taskModal.dataset(), value: 'configure-datasets' },
        { label: LL.value.taskModal.reportingEntity(), value: 'configure-reporting-entities' },
        { label: LL.value.taskModal.user(), value: 'configure-users' },
      ],
    },
  ]

  linkData.push({
    label: LL.value.sideBar.materiality(),
    value: 'materiality',
    icon: 'dataTreeIcon',
    children: [
      { label: LL.value.taskModal.materiality.overview(), value: 'report-materiality-overview' },
      { label: LL.value.taskModal.materiality.stakeholders(), value: 'report-materiality-stakeholders' },
      { label: LL.value.taskModal.materiality.impacts(), value: 'report-materiality-impacts' },
      { label: LL.value.taskModal.materiality.risksOpportunities(), value: 'report-materiality-risks-opportunities' },
    ],
  })

  return linkData
}

export function statusForTasks(LL: Ref<TranslationFunctions>, excluding: string) {
  const statusData: LabeledValue<string>[] = [
    { label: LL.value.taskModal.statusText['to-do'](), value: 'to-do' },
    { label: LL.value.taskModal.statusText['in-progress'](), value: 'in-progress' },
    { label: LL.value.taskModal.statusText.review(), value: 'review' },
    { label: LL.value.taskModal.statusText.done(), value: 'done' },
  ]
  return statusData.filter((data) => data.value !== excluding)
}

export function getDueDate(date: string) {
  const differenceInDate = new Date(date).getTime() - new Date().getTime()
  return Math.ceil(differenceInDate / (1000 * 3600 * 24))
}

export function getKwargsForTasks(refPage: string, kwargs: Record<string, string>) {
  let retKwargs = {} as Record<string, string>
  switch (refPage) {
    case 'measure':
      retKwargs = {
        ...(kwargs['category'] ? { category: kwargs['category'] } : {}),
        ...(kwargs['action'] ? { action: kwargs['action'] } : {}),
      }
      break
    default:
      retKwargs = kwargs
  }
  return retKwargs
}

export function getTaskColor(status: string) {
  switch (status) {
    case 'in-progress':
    case 'review':
      return 'bg-yellow-light text-yellow-110'
    case 'done':
      return 'bg-green-light text-green-dark'
    default:
      return 'bg-gray-btn text-gray-dark'
  }
}
