<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import SelectButton from 'primevue/selectbutton'

export interface Props {
  disabled?: boolean
  invalid?: boolean
  options: LabeledValue<Primitive | null | undefined>[]
}

const value = defineModel()
const props = defineProps<Props>()

const selectedLabel = computed(() => {
  const selectedOption = props.options.find((opt) => opt.value === value.value)
  return selectedOption?.label ?? ''
})
const selection = ref({ label: selectedLabel.value, value: value.value })

watch(selection, (newSelection, oldSelection) => {
  if (newSelection !== oldSelection) value.value = newSelection.value
})

const pt = {
  root: () => ({
    class: [
      'rounded-lg border border-gray-border p-1',
      { 'opacity-50 select-none pointer-events-none cursor-default': props.disabled },
    ],
  }),
  button: ({ context }: any) => ({
    class: [
      'relative leading-none inline-flex items-center align-bottom text-center px-4 py-3  rounded-md',
      {
        'bg-white dark:bg-black': !context.active,
        'text-black dark:text-white': !context.active,
        'border-0': !context.active && !props.invalid,
        'bg-blue-100 dark:bg-blue text-blue dark:text-white': context.active,
      },
      { 'border-red dark:border-red-light': props.invalid },
      {
        'hover:bg-blue-100 hover:text-blue dark:hover:bg-blue dark:hover:text-white': !context.active && !props.invalid,
        'hover:bg-blue-100 hover:text-blue': context.active,
      },
      { 'opacity-50 select-none pointer-events-none cursor-default': context.disabled },
      'transition duration-200 cursor-pointer select-none overflow-hidden',
    ],
  }),
  label: {
    class: 'font-bold',
  },
}
</script>

<template>
  <SelectButton :pt v-bind="{ options, disabled, invalid }" v-model="selection" optionLabel="label" />
</template>
