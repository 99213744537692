import { defineStore } from 'pinia'
import type { AxiosError } from 'axios'
import { defaultPageSize } from '@api/client'
import { createTasks, deleteTask, editTask, getTasks, getTask } from '@composable/useApi'
import { useReportingEntitiesStore } from '@store/reportingEntities'
import { useSnackStore } from '@store/snackStore'
import { fetchUser, formatUser } from '@util/reporting'
import type { SelectBoxOption } from '@component/forms/SelectBox.vue'
import { useDatasetStore } from '@store/dataset'
import type { User } from './user'

export interface LinkDataItem {
  page?: string
  name?: string
  kwargs?: Record<string, string>
}

export interface TaskCreateObj {
  title: string
  description: string
  assignee_id: string
  status: string
  due_at: string
  link_data: LinkDataItem
  reporting_entity_id?: string
}

export interface TaskItem {
  id: string
  title: string
  description: string
  assignee_id: string
  assigner: string
  reporting_entity: string
  status: string
  due_at: string
  link_data: LinkDataItem
  assignee?: {
    id: string
    email: string
    first_name: string
    last_name: string
    is_external: boolean
  }
  assigneeUser?: SelectBoxOption | null
}

export const useTasksStore = defineStore('tasks', {
  state: () =>
    ({
      data: [],
      current: null,
      loading: true,
      ready: false,
      statusCode: 0,
      statusMessage: '',
      pagination: {
        currentPage: 1,
        pageCount: 1,
        count: 0,
      },
    }) as StoredData<TaskItem>,

  getters: {
    isOK(state) {
      return state.statusCode >= 200 && state.statusCode < 300
    },
  },
  actions: {
    resetState() {
      this.data = []
      this.current = null
      this.loading = false
      this.ready = false
      this.statusCode = 0
      this.statusMessage = ''
    },
    resetVars() {
      this.loading = false
      this.ready = false
      this.statusCode = 0
      this.statusMessage = ''
    },
    async fetchTasks(page = 1, perPage = defaultPageSize) {
      this.resetState()
      const snackBar = useSnackStore()
      try {
        const reportingEntity = useReportingEntitiesStore()
        const paginatedData = await getTasks(reportingEntity.current?.id as string, page, perPage, true)
        this.data = paginatedData.results.map((task) => {
          task.assigneeUser = formatUser(task.assignee as User)
          return task
        })

        this.pagination = {
          currentPage: page,
          pageCount: Math.ceil(paginatedData.count / perPage),
          count: paginatedData.count,
        }
        this.statusCode = 200
        this.statusMessage = 'OK'
      } catch (error) {
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        snackBar.error('issueLoadingTasks')
      } finally {
        this.loading = false
        this.ready = true
      }
    },

    async getTaskCount() {
      const reportingEntity = useReportingEntitiesStore()
      const data = await getTasks(reportingEntity.current?.id as string, 1, 10)
      this.pagination
        ? (this.pagination.count = data.count)
        : (this.pagination = { count: data.count, currentPage: 1, pageCount: 1 })
    },

    async getTask(taskId: string) {
      const task = this.data.find((task) => task.id === taskId)
      if (task) {
        this.current = task
      } else {
        const response = await getTask(taskId)
        this.current = response
      }
    },

    getFilteredTasks(pageName: string): TaskItem[] {
      return this.data.filter((task) => task.link_data.name === pageName)
    },

    async createNewTask(taskObj: TaskCreateObj) {
      try {
        this.loading = true
        const reportingEntity = useReportingEntitiesStore()
        const datasetStore = useDatasetStore()
        const currentDataset = datasetStore.current
        if (currentDataset) {
          taskObj.link_data.kwargs = { ...taskObj.link_data.kwargs, dataset_id: currentDataset.id }
        }
        const response = await createTasks({ ...taskObj, reporting_entity_id: reportingEntity.current?.id as string })
        response.assigneeUser = await fetchUser(response.assignee_id)
        this.data.push(response)
        this.data = this.data.sort((a, b) => {
          return new Date(b.due_at).getTime() - new Date(a.due_at).getTime()
        })
        if (this.pagination && this.pagination.count) this.pagination.count++
      } catch (error) {
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
      } finally {
        this.loading = false
        this.ready = true
      }
    },

    async editSelectedTask(taskObj: Partial<TaskCreateObj>, taskId: string) {
      const snackBar = useSnackStore()
      try {
        // this.loading = true
        const reportingEntity = useReportingEntitiesStore()
        const response = await editTask(
          { ...taskObj, reporting_entity_id: reportingEntity.current?.id as string },
          taskId,
        )
        const taskIndex = this.data.findIndex((task) => task.id === taskId)
        response.assigneeUser = await fetchUser(response.assignee_id)
        this.data[taskIndex] = response
        this.data = this.data.sort((a, b) => {
          return new Date(b.due_at).getTime() - new Date(a.due_at).getTime()
        })
        snackBar.success('taskEdited')
      } catch (error) {
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        snackBar.error('issueEditingTask')
      } finally {
        this.loading = false
        this.ready = true
        this.current = null
      }
    },

    async deleteSelectedTask(taskId: string) {
      const snackBar = useSnackStore()
      try {
        this.loading = true
        const reportingEntity = useReportingEntitiesStore()
        await deleteTask(reportingEntity.current?.id as string, taskId)
        this.data = this.data.filter((task) => task.id !== taskId)
        if (this.pagination && this.pagination.count) this.pagination.count--
        snackBar.success('taskDeleted')
      } catch (error) {
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        snackBar.error('issueDeletingTask')
      } finally {
        this.loading = false
        this.ready = true
      }
    },
  },
})
