<script setup lang="ts">
import AppButton from '@component/forms/AppButton.vue'
import LoadingIndicator from '@component/LoadingIndicator.vue'
import Indicator from '@component/Indicator.vue'
import useLocales from '@composable/useLocales'
import { useDatasetStore } from '@/stores/dataset'
import { definePage } from 'vue-router/auto'
definePage({
  redirect: '/measure/upload/invoices',
})
const { LL } = useLocales()
const dataset = useDatasetStore()
</script>
<template>
  <div class="flex justify-between items-center max-w-[44%]">
    <div class="flex p-1 bg-gray-btn dark:bg-black-soft rounded-lg">
      <AppButton
        label="PDF"
        asLinkTo="/measure/upload/invoices"
        class="mr-2"
        :tab-btn="$route.name !== 'measure-upload-invoices'"
        data-cy="measure__upload-invoices-button"
      />
      <AppButton
        :label="LL.measure.templates()"
        asLinkTo="/measure/upload/spreadsheets"
        :tab-btn="$route.name !== 'measure-upload-spreadsheets'"
        data-cy="measure__upload-spreadsheets-button"
      />
    </div>
    <Indicator v-if="dataset.isClosed" icon="lockedIcon" :message="LL.datasetClosed()" />
  </div>
  <div class="w-full h-full py-6 max-w-screen-2xl" :class="dataset.isClosed ? 'pointer-events-none opacity-50' : ''">
    <Suspense>
      <RouterView />
      <template #fallback>
        <LoadingIndicator class="mt-32" />
      </template>
    </Suspense>
  </div>
</template>

<route lang="yaml">
meta:
  locales:
    - measure
</route>
