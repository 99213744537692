<script setup lang="ts">
import LoadingIndicator from '@component/LoadingIndicator.vue'
import AppButton from '@component/forms/AppButton.vue'
import useLocales from '@composable/useLocales'
import { definePage } from 'vue-router/auto'
const { LL } = useLocales()
definePage({
  redirect: '/measure/history/invoices',
})
</script>
<template>
  <div class="flex justify-start items-center">
    <div class="flex p-1 bg-gray-btn dark:bg-black-soft rounded-lg">
      <AppButton
        label="PDF"
        asLinkTo="/measure/history/invoices"
        class="mr-2"
        :tab-btn="$route.name !== 'measure-history-invoices'"
      />
      <AppButton
        :label="LL.measure.templates()"
        asLinkTo="/measure/history/spreadsheets"
        :tab-btn="$route.name !== 'measure-history-spreadsheets'"
      />
    </div>
  </div>
  <div class="w-full h-full py-6 max-w-screen-2xl">
    <Suspense>
      <RouterView />
      <template #fallback>
        <LoadingIndicator class="mt-32" />
      </template>
    </Suspense>
  </div>
</template>

<route lang="yaml">
meta:
  locales:
    - measure
</route>
