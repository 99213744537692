<script setup lang="ts">
import { ref } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { FormKitIcon } from '@formkit/vue'
import useLocales from '@composable/useLocales'
import { useSnackStore } from '@store/snackStore'

export interface Props {
  heading?: I18NSnackHeadingKey
  description: I18NSnackMessageKey | string
  id: string
  showCloseIcon?: boolean
  theme?: string
  sideIcon?: string
}

withDefaults(defineProps<Props>(), { showCloseIcon: false,theme: '',sideIcon: '', })
const { LL } = useLocales()
const isOpen = ref(true)
const snackBar = useSnackStore()

function closeModal() {
  isOpen.value = false
  snackBar.deleteSnackBar(__props.id)
}

function getSnackTheme(): string {
  let retTheme = ''
  switch (__props.theme) {
    case 'primary':
      retTheme = 'bg-primary text-white dark:bg-white dark:text-primary'
      break
    case 'secondary':
      retTheme = 'bg-white text-black border-2 border-black dark:bg-gray dark:text-white dark:border-white'
      break
    case 'success':
      retTheme = 'bg-black text-white dark:bg-white dark:text-black'
      break
    case 'warning':
      retTheme = 'bg-warning text-white dark:bg-white dark:text-warning'
      break
    case 'error':
      retTheme = 'bg-error text-white dark:bg-white dark:text-error'
      break
    default:
      retTheme = 'bg-white text-black dark:bg-white dark:text-black shadow-sm shadow-black-soft dark:shadow-white-soft'
      break
  }
  return retTheme
}
</script>

<template>
  <div :id="`${id}-snack`" class="w-full flex justify-center">
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal()">
        <div class="relative">
          <div class="flex min-h-full items-center justify-center text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                :class="`flex justify-between items-center w-64 max-w-lg min-h-[40px] ${getSnackTheme()} transform overflow-hidden rounded-lg py-1 text-left shadow-2xl transition-all`"
              >
                <div
                  v-if="sideIcon !== ''"
                  :class="`flex fill-white dark:fill-black justify-between items-center mx-4`"
                >
                  <FormKitIcon class="w-6 text-base cursor-pointer" :icon="sideIcon" />
                </div>
                <div :class="`${sideIcon === '' ? 'w-full' : 'w-11/12 pr-4'}`">
                  <DialogTitle v-if="heading" as="h3" class="text-lg font-bold leading-4">
                    {{ LL.snackBar.headings[heading as I18NSnackHeadingKey]() }}
                  </DialogTitle>
                  <FormKitIcon
                    v-if="showCloseIcon"
                    class="absolute top-1 right-4 w-5 h-5 cursor-pointer"
                    :icon="'close'"
                    @click="closeModal"
                  />
                  <div v-if="description" :class="`${heading ? 'mt-2' : ''}`">
                    <p :class="`text-base text-gray-500 py-1 ${sideIcon !== '' ? 'pr-4' : 'px-4'} `">
                      {{ LL.snackBar.descriptions[description as I18NSnackMessageKey]() || description }}
                    </p>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<style>
#headlessui-portal-root {
  position: fixed;
  z-index: 60;
  top: 16px;
  right: 16px;
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  align-items: flex-end;
}
</style>
