<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { reactive } from 'vue'
import { min } from 'date-fns'

import { useTasksStore, type TaskItem, type TaskCreateObj } from '@store/tasks'
import useLocales from '@composable/useLocales'
import { linkDataForTasks, getDueDate } from '@util/tasks'
import { fetchUsers, fetchUser } from '@util/reporting'

import Dialog from '@component/NewDialog.vue'
import AppButton from '@component/forms/AppButton.vue'
import TextField from '@component/forms/TextField.vue'
import InputField from '@component/QuestionnaireSection/InputField.vue'
import SelectBox, { type ComplexOption } from '@component/forms/SelectBox.vue'
import UserOption from '@component/QuestionnaireSection/UserOption.vue'
import TaskStatus from '@component/Tasks/TaskStatus.vue'
import { FormKitIcon } from '@formkit/vue'
import NestedSelect from '@component/NestedSelect.vue'
import type { TreeNode } from 'primevue/treenode'

export interface Props {
  task: TaskItem
  showTaskViewDialog: boolean
  displayName: string
  initials: string
  isAllowedToEdit: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update', value: boolean): void
  (e: 'delete'): void
  (e: 'cancel'): void
}>()

const { LL } = useLocales()
const taskStore = useTasksStore()

const taskFormData: TaskCreateObj = reactive({
  title: props.task.title,
  description: props.task.description,
  assignee_id: props.task.assignee_id,
  status: props.task.status,
  due_at: props.task.due_at,
  link_data: {
    name: props.task.link_data.name || props.task.link_data.page,
    kwargs: { ...(props.task.link_data.kwargs ?? props.task.link_data.kwargs) },
  },
})

let assignedUser: ComplexOption | null = _ref(null)
let showInlineTitleEdit: 'title' | 'description' | 'user' | null = _ref(null)
let assigneeChanged = _ref(false)

const linkData: TreeNode[] = linkDataForTasks(LL)

const dueDate = _computed(() => {
  return getDueDate(taskFormData.due_at as string)
})

function resetFormData() {
  taskFormData.due_at = props.task.due_at
  taskFormData.link_data = props.task.link_data
  taskFormData.status = props.task.status
  taskFormData.title = props.task.title
  taskFormData.description = props.task.description
  showInlineTitleEdit.value = null
  emit('cancel')
}

const saveEditedValues = () => {
  if (taskFormData.title && taskFormData.assignee_id && taskFormData.due_at) {
    taskStore.editSelectedTask(taskFormData, props.task.id)
    emit('cancel')
  }
}

async function setAssignedUser(userId: string | null = null) {
  if (userId) {
    assignedUser.value = await fetchUser(userId)
    taskFormData.assignee_id = userId
    assigneeChanged.value = true
    showInlineTitleEdit.value = null
    return
  }
  assignedUser.value = taskFormData.assignee_id ? await fetchUser(taskFormData.assignee_id) : null
}

function resetAssignee() {
  taskFormData.assignee_id = ''
  assignedUser.value = null
}

function updateLinkedName(name: string) {
  if (taskFormData.link_data) taskFormData.link_data.name = name
  else taskFormData.link_data = { name }
}

setAssignedUser()
</script>
<template>
  <Dialog
    v-if="showTaskViewDialog"
    type="confirm"
    static
    :header="''"
    max-width="650px"
    textColor="dark:text-white"
    no-footer
    zIndex="100"
    @cancel="resetFormData"
  >
    <template #header>
      <div class="flex items-center w-full justify-between items-start">
        <div class="flex items-center gap-2 font-medium text-lg">
          <FormKitIcon
            class="inline-block mr-2 rounded-md cursor-pointer text-gray-mid dark:text-white"
            :icon="props.task.link_data?.name?.includes('report') ? 'reportingIcon' : 'tickSquareIcon'"
            :title="props.task.link_data?.name || 'dnd'"
            @click="showInlineTitleEdit = 'title'"
          />
          <div v-if="showInlineTitleEdit !== 'title'" @click="showInlineTitleEdit = 'title'">
            {{ taskFormData.title }}
          </div>
          <div>
            <TextField
              v-if="showInlineTitleEdit === 'title'"
              :validation-label="LL.taskModal.title()"
              required
              :disabled="!isAllowedToEdit"
              v-model="taskFormData.title"
              @onkeyEnter="!taskFormData.title ? (showInlineTitleEdit = 'title') : (showInlineTitleEdit = null)"
              @onblur="!taskFormData.title ? (showInlineTitleEdit = 'title') : (showInlineTitleEdit = null)"
            />
          </div>
        </div>
        <div class="flex p-2 bg-gray-btn rounded-md cursor-pointer" @click="resetFormData">
          <FormKitIcon icon="closeIcon" class="dark:text-black" />
        </div>
      </div>
    </template>
    <div class="w-100 flex flex-col gap-4 items-start my-6">
      <div class="flex justify-around gap-4">
        <div class="min-w-20" @click="showInlineTitleEdit = 'description'">{{ LL.taskModal.details() }}</div>
        <div v-if="showInlineTitleEdit !== 'description'" @click="showInlineTitleEdit = 'description'">
          {{ taskFormData.description }}
        </div>
        <InputField
          v-if="showInlineTitleEdit === 'description'"
          :classes="'min-w-[30vw] 2xl:min-w-[450px] min-h-20 p-1'"
          type="textarea"
          :value="taskFormData.description as string"
          @update="taskFormData.description = $event as string"
          @onblur="showInlineTitleEdit = null"
        />
      </div>
      <div class="flex justify-around items-center gap-4">
        <div class="min-w-20">{{ LL.taskModal.link() }}</div>
        <NestedSelect
          :items="linkData"
          :value="taskFormData.link_data.name"
          @select="updateLinkedName"
          :disabled="!isAllowedToEdit"
        />
      </div>
      <div class="flex flex-row gap-4 items-center justify-around">
        <div class="min-w-20">{{ LL.taskModal.dueDate() }}</div>
        <div class="flex flex-row items-center gap-2 justify-around">
          <TextField
            date
            required
            :disabled="!isAllowedToEdit"
            v-model="taskFormData.due_at"
            :validation-label="LL.taskModal.dueDate()"
            :min="
              min([new Date(), new Date(props.task.due_at)])
                .toISOString()
                .split('T')[0]
            "
            :placeholder="LL.taskModal.selectDate()"
          />
          <div>
            <span v-if="dueDate > 0">in {{ dueDate }} {{ LL.taskModal.day(dueDate) }}</span>
            <span class="text-yellow" v-if="dueDate === 0">{{ LL.taskModal.dueToday() }}</span>
            <span class="text-red" v-else-if="dueDate < 0"
              >{{ dueDate * -1 }} {{ LL.taskModal.day(dueDate) }} {{ LL.taskModal.overdue() }}</span
            >
          </div>
        </div>
      </div>
      <div class="flex flex-row gap-4 items-center justify-around">
        <div class="min-w-20">{{ LL.taskModal.assignee() }}</div>
        <div
          v-if="showInlineTitleEdit !== 'user'"
          class="flex items-center h-10 mr-2 bg-background dark:bg-gray-mid rounded-full"
          @click="showInlineTitleEdit = 'user'"
        >
          <div class="min-w-10 p-2 text-center font-semibold bg-gray-border dark:bg-gray rounded-full">
            {{ assigneeChanged ? assignedUser?.meta?.initials : initials }}
          </div>
          <div
            class="items-center px-3 py-2 dark:bg-gray-mid overflow-ellipsis line-clamp-1 rounded-full"
            :title="displayName"
          >
            {{ assigneeChanged ? assignedUser?.label : displayName }}
          </div>
        </div>
        <SelectBox
          v-if="showInlineTitleEdit === 'user'"
          class="w-full mb-4"
          required
          autocomplete
          :options="fetchUsers"
          prefix-icon="search"
          :placeholder="LL.taskModal.searchUsers()"
          :label="LL.taskModal.selectAssignee()"
          :model-value="assignedUser"
          @input="setAssignedUser($event as string)"
        >
          <template #selection="{ label, meta }" v-if="taskFormData.assignee_id">
            <UserOption :label="label" :sub-label="meta.email" :initials="meta.initials" :external="meta.external" />
            <button @click="resetAssignee">
              <FormKitIcon icon="closeIcon" class="w-6" />
            </button>
          </template>
          <template #option="{ label, meta }">
            <UserOption
              big
              :label="label"
              :sub-label="meta.email"
              :initials="meta.initials"
              :external="meta.external"
            />
          </template>
        </SelectBox>
      </div>
      <div class="flex flex-row gap-4 items-center justify-around">
        <div class="min-w-20">{{ LL.taskModal.status() }}</div>
        <TaskStatus
          :disabled="!isAllowedToEdit"
          :status="taskFormData.status"
          @update="taskFormData.status = $event"
          menu-classes="w-32"
          vAlign="top"
        />
      </div>
    </div>

    <footer>
      <div class="flex flex-row justify-between items-start mt-4">
        <FormKitIcon
          :class="`inline-block w-10 p-2 rounded-md bg-red-10 text-red-110 ${
            isAllowedToEdit ? 'cursor-pointer' : 'cursor-not-allowed'
          }`"
          icon="deleteIcon"
          @click="/* resetFormData(); */ isAllowedToEdit ? emit('delete') : null"
        />
        <AppButton
          primary
          :disabled="!isAllowedToEdit"
          :label="LL.save()"
          @click="isAllowedToEdit ? saveEditedValues() : null"
        />
      </div>
    </footer>
  </Dialog>
</template>
