import { createRouter, createWebHistory, type NavigationGuard } from 'vue-router/auto'
import { useUserStore } from '@store/user'
import { useDatasetStore } from '@store/dataset'
import { HOME_ROUTE, isNonAuthRoute, ROUTE_ITEMS } from '@/util/routes'
import usePermissions from '@composable/usePermissions'
/// Allows to change feature flags temporarily via URL params,
/// as long as the application is in development mode.
/// For example app.url/?~ff.dashboard.helpfulErrors=true
export const featureFlagGuard: NavigationGuard = () => {
  // TODO
}

// checking reporting scope ID and if the route is beyong users access
// limiting their navigation
export const reportingScopeGuard: NavigationGuard = (to) => {
  const datasetStore = useDatasetStore()
  const reportingScopeId = datasetStore.current?.reporting_scope_id
  const { isAllowedList } = usePermissions()
  const onlyEsgRoutes = ['dashboard-social', 'dashboard-governance']

  if (reportingScopeId && reportingScopeId !== 'esg') {
    if (onlyEsgRoutes.includes(to.name as string)) {
      return isAllowedList.value.parent.length > 0
        ? ROUTE_ITEMS[isAllowedList.value.parent[0] as I18NRoutePermissionLabels]
        : HOME_ROUTE
    }
  }
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const loginGuard: NavigationGuard = (to) => {
  const { loggedIn, isLoading } = useUserStore()
  const { isAllowedList } = usePermissions()
  if (isLoading) {
    // user is still being loaded, so don't do anything yet
    return
  } else {
    if (loggedIn) {
      // user is logged in, so no need for login and signup pages
      if (isNonAuthRoute(to.path as string)) {
        return isAllowedList.value.parent.length > 0
          ? [isAllowedList.value.parent[0] as I18NRoutePermissionLabels]
          : HOME_ROUTE
      }
    } else {
      // user is not logged in, so redirect them to login
      if (!isNonAuthRoute(to.path as string)) {
        return { name: 'login', query: { origin: to.path } }
      }
    }
  }
}

/** create vue-router with automatic routing and navigation guards
 *
 * @param skipGuards: if true, do not register any navigation guards, default: false
 */
export default function create(skipGuards = false) {
  const router = createRouter({
    history: createWebHistory(),
    // one little catch with using auto router is that redirects are a bit quirky
    // see https://github.com/posva/unplugin-vue-router/discussions/63 for more
    extendRoutes: (routes) => {
      const root = routes.find((r) => r.path === '/')

      if (root) root.redirect = HOME_ROUTE
      else
        routes.push({
          path: '/',
          name: 'index',
          redirect: HOME_ROUTE,
        })

      return routes
    },
  })

  if (!skipGuards) {
    // TODO:
    // router.beforeEach(featureFlagGuard)
    router.beforeEach(loginGuard)
    router.beforeEach(reportingScopeGuard)
  }

  return router
}
