<script setup lang="ts">
import { definePage } from 'vue-router/auto'
import PageLayout from '@layout/OverviewPage.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import AppButton from '@component/forms/AppButton.vue'

// the route block doesn't work for redirects
// see: https://github.com/posva/unplugin-vue-router/discussions/63
definePage({
  redirect: '/improve/goals',
})
</script>

<template>
  <PageLayout
    class="bg-pages-background dark:bg-black-soft"
    :class="{ 'mb-0 bg-white dark:bg-black-soft': $route.name === 'improve-measures' }"
  >
    <template #back-btn="{ LL }" v-if="$route.name.includes('measures')">
      <AppButton :label="LL.improve.goals.allMeasures()" asLinkTo="/improve/goals" tab-btn icon="caretLeftIcon" />
    </template>
    <div>
      <Suspense>
        <RouterView />
        <template #fallback>
          <LoadingIndicator class="mt-32" />
        </template>
      </Suspense>
    </div>
  </PageLayout>
</template>
