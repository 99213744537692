<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { FormKitIcon } from '@formkit/vue'

interface Item {
  id: number | string
  title: string
  value: boolean
}

interface Props {
  parent: string
  items: Item[]
  opened?: boolean
}

withDefaults(defineProps<Props>(), { opened: false, })
const isOpen = _ref(__props.opened)

const parentValue = _computed(() => {
  if (__props.items.length === 0) return false

  return __props.items.every((item) => item.value)
})

const emit = defineEmits<{
  (e: 'change', value: Item): void
}>()

const handleClick = (item: Item) => {
  emit('change', { ...item, value: !item.value })
}

const handleParentClick = (value?: boolean) => {
  if (value === undefined) return
  __props.items.forEach((item) => {
    emit('change', { ...item, value })
  })
}
</script>

<template>
  <div class="flex flex-col gap-2">
    <div class="flex gap-2 items-center">
      <FormKit
        type="checkbox"
        :label="parent"
        :value="parentValue"
        :key="`parent-${parentValue}`"
        wrapper-class="flex justify-start gap-2 items-center"
        input-class="w-5 h-5 accent-primary"
        inner-class="flex item-center"
        @input="handleParentClick"
      />
      <FormKitIcon
        icon="arrowDown"
        class="w-5 cursor-pointer -rotate-90"
        :class="{ 'origin-center transition-transform rotate-0': isOpen }"
        @click="isOpen = !isOpen"
      />
    </div>

    <div v-for="item in items" v-show="isOpen" :key="item.id" class="flex flex-col gap-2 px-4 hover:bg-gray/10">
      <FormKit
        type="checkbox"
        :label="item.title"
        :value="item.value"
        :key="`item-${item.value}`"
        label-class="font-sm"
        wrapper-class="flex justify-start gap-2 items-center"
        input-class="w-5 h-5 accent-primary"
        inner-class="flex item-center"
        @click="handleClick(item)"
      />
    </div>
  </div>
</template>
