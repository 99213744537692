<script setup lang="ts">import { ref as _ref } from 'vue';

import { watch } from 'vue'
import LoadingSpinner from '@component/LoadingSpinner.vue'
import { FormKitIcon } from '@formkit/vue'
import { onClickOutside, computedAsync } from '@vueuse/core'
import useLocales from '@composable/useLocales'

export type ComplexOption = LabeledValue<Primitive> & { meta?: any }
export type SelectBoxOption = Primitive | ComplexOption

export type SearchFunction = (needle?: string) => Promise<SelectBoxOption[]>

export interface Props {
  options: SelectBoxOption[] | SearchFunction
  modelValue?: SelectBoxOption | null
  label?: string
  placeholder?: string
  prefixIcon?: string
  autocomplete?: boolean
  hue?: 'blue' | 'red' | 'green' | 'gray' | 'black'
  disabled?: boolean
  required?: boolean
  alignLeft?: boolean
  keepOpen?: boolean
  aggregateButton?: boolean
}
export interface Emits {
  (e: 'input', value: Primitive): void
  (e: 'update:modelValue', value: Primitive): void
  (e: 'open'): void
  (e: 'close'): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()
const { LL } = useLocales()

function isPrimitive(option: any): option is Primitive {
  const t = typeof option
  return t === 'string' || t === 'number' || t === 'boolean'
}

function labelFor(value: any): string {
  if (!Array.isArray(props.options)) return value?.label ?? `${value}`
  const option = isPrimitive(value)
    ? props.options.find((o) => (isPrimitive(o) ? o === value : o.value === value))
    : value
  return option?.label ?? `${option}`
}

function valueFor(option: any): Primitive | null {
  if (isPrimitive(option)) return option
  return option?.value ?? null
}

function metaFor(option: any): any {
  return option?.meta ?? {}
}

const selectBoxElement = _ref<HTMLDivElement | null>(null)
const searchInput = _ref<HTMLInputElement | null>(null)

let showOptions = _ref(false)
let currentSelection = _ref<SelectBoxOption | null>(null)
let needle = _ref('')
const optionsLoading = _ref(false)

const calculatedOptions = computedAsync(
  async () => {
    if (typeof props.options !== 'function') return props.options ?? []
    if (!props.autocomplete) return await props.options()
    return await props.options(needle.value)
  },
  [],
  (optionsLoading),
)

let isError = _ref<boolean>(false)

watch(
  [props, calculatedOptions],
  async () => {
    const value = props.modelValue
    if (value === undefined) {
      currentSelection.value = null
    } else {
      currentSelection.value = value ?? null
    }
  },
  { immediate: true },
)

function handleClick() {
  showOptions.value = true
  if (searchInput.value !== null) searchInput.value.focus()
  emit('open') // TODO: emit open before or after focussing the input?
}

onClickOutside((selectBoxElement), () => {
  // TODO: this listener is called even when closed
  if (!showOptions.value) return
  showOptions.value = false
  isError.value = !currentSelection.value && props.required
  emit('close')
})

function select(option?: SelectBoxOption): void {
  const value = valueFor(option)
  currentSelection.value = option ?? null
  if (!props.keepOpen) {
    needle.value = ''
    showOptions.value = false
    emit('close')
  }
  isError.value = false

  emit('input', value as Primitive)
  emit('update:modelValue', value as Primitive)
}

function closeOptions() {
  showOptions.value = false
  emit('close')
}
</script>

<template>
  <div class="flex flex-col my-2">
    <div class="my-0.5 text-gray-dark dark:text-gray-light" v-if="label">
      {{ label }}
    </div>
    <div
      role="listbox"
      :aria-expanded="showOptions"
      ref="selectBoxElement"
      class="relative h-10 px-2 rounded-lg"
      :class="{
        'bg-white dark:bg-black border border-gray-border ': hue === undefined && !showOptions && !aggregateButton,
        'bg-red/20 dark:bg-red/30 border border-gray-mid dark:border-gray-border': hue === 'red',
        'bg-blue/20 dark:bg-blue/20 border border-gray-mid dark:border-gray-border': hue === 'blue',
        'bg-green/50 dark:bg-green border border-gray-mid dark:border-gray-border': hue === 'green',
        'bg-gray/20 dark:bg-gray-dark border border-gray-mid dark:border-gray-border': hue === 'gray',
        'bg-black-soft dark:bg-white-soft border border-gray dark:border-gray-dark text-white dark:text-black':
          hue === 'black',
        'px-4 h-11 bg-white border border-gray/30 dark:bg-gray-dark': aggregateButton,
        'border border-black dark:border-gray-mid': showOptions && !aggregateButton,
        'cursor-pointer': !disabled,
        'cursor-not-allowed': disabled,
      }"
    >
      <div
        class="flex items-center justify-between gap-2 h-10"
        @click="handleClick"
        data-cy="report-requirements__assign-and-status-button"
      >
        <FormKitIcon icon="configurationIcon" v-if="aggregateButton" />
        <slot
          name="selection"
          v-bind="{
            label: labelFor(currentSelection),
            value: valueFor(currentSelection),
            meta: metaFor(currentSelection),
          }"
        >
          <FormKitIcon class="w-6" :class="{ 'text-gray-light': disabled }" :icon="prefixIcon" />
          <input
            v-if="autocomplete && !currentSelection"
            v-model="needle"
            ref="searchInput"
            :placeholder="placeholder"
            class="w-full text-base focus:outline-0 outline-2 outline-red"
            :style="/* bg-transparent class is ignored?! */ 'background-color: transparent'"
          />
          <div v-else class="w-full text-base" :class="{ 'text-gray-light': disabled }">
            {{ currentSelection ? labelFor(currentSelection) : placeholder || '' }}
          </div>
          <LoadingSpinner class="w-6" v-if="optionsLoading" />
          <FormKitIcon class="w-6" icon="arrowDown" v-else />
        </slot>
        <FormKitIcon class="ml-2 w-6" icon="arrowDown" v-if="aggregateButton" />
      </div>
      <div v-if="isError" class="text-red">{{ LL.selectBox.required() }}</div>

      <Transition name="fade" v-if="!disabled">
        <div
          v-if="showOptions"
          class="absolute top-[100%] bottom-[auto] w-full min-w-[360PX] max-h-[50vh] overflow-y-auto py-2 ring ring-white rounded-lg bg-black text-white scale-100 z-50"
          :class="alignLeft ? 'left-0' : 'right-0'"
        >
          <ol v-if="calculatedOptions.length" class="w-full flex flex-col gap-px rounded-lg bg-gray">
            <slot name="options" v-bind="{ options: calculatedOptions, select }">
              <li
                v-for="(option, index) in calculatedOptions"
                :key="`${valueFor(option)}`"
                @click="select(option)"
                role="option"
                class="px-3 py-2 bg-black hover:bg-gray-dark first:rounded-t-lg last:rounded-b-lg"
              >
                <slot
                  name="option"
                  v-bind="{
                    label: labelFor(option),
                    value: valueFor(option),
                    meta: metaFor(option),
                    index,
                  }"
                >
                  {{ labelFor(option) }}
                </slot>
              </li>
            </slot>
          </ol>
          <div v-else class="p-2">
            <slot
              name="actions"
              v-bind="{
                sublabel: needle,
                closeOptions,
              }"
            >
              <div class="w-full h-44 flex justify-center items-center p-2 text-sm text-gray-light">
                {{ LL.noOptionsForSearch() }}
              </div>
            </slot>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>
