<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, ref, computed, onMounted, watch } from 'vue'
import { RouterView, useRoute } from 'vue-router'
import { useMagicKeys, whenever } from '@vueuse/core'
import { isNonAuthRoute } from '@util/routes'

import DynamicDialog from 'primevue/dynamicdialog'
import CGToast from '@component/CGToast.vue'
import LoadingIndicator from '@component/LoadingIndicator.vue'
import SnackBar from '@component/SnackBar.vue'
import FeatureFlags from '@component/FeatureFlags.vue'
import Dialog from '@component/NewDialog.vue'
import Onboarding from '@component/Onboarding.vue'
import AddTask from '@component/Tasks/AddTask.vue'
import OngoingProcessIndicator from '@component/OngoingProcessIndicator.vue'

import { useSnackStore } from '@store/snackStore'
import { useContextualHelpStore } from '@store/contextual-help'
import { useReportingRequirementStore } from '@store/reporting/requirements'
import { useUserStore } from '@store/user'
import { useDatasetStore } from '@store/dataset'
import useLoaderMessages from '@composable/useLoader'
import useDisclosureDraftBatches from '@composable/useDisclosureDraftBatches'
import TaskDetail from '@component/Tasks/TaskDetail.vue'

const userStore = useUserStore()
const datasetStore = useDatasetStore()

const finishedLoading = computed(() => {
  return userStore.ready && datasetStore.ready && !userStore.isLoading
})
const { startLoading, loadingMessage } = useLoaderMessages(finishedLoading)
startLoading()

const AppSidebar = defineAsyncComponent(() => import('@component/AppSidebar.vue'))
const ContextualHelpSidebar = defineAsyncComponent(() => import('@component/ContextualHelpSidebar.vue'))
const DisclosureHelpSidebar = defineAsyncComponent(() => import('@component/QuestionnaireSection/HelpSideBar.vue'))

const route = useRoute()
const showExtras = computed(() => !route.meta?.hideMenu)
const { snackData } = storeToRefs(useSnackStore())

const { showHelp: showContextualHelpBar } = storeToRefs(useContextualHelpStore())
const { showHelp: showDisclosureHelpBar } = storeToRefs(useReportingRequirementStore())

const isTaskPage = computed(() => {
  if (!route?.name) return false
  return route.name.includes('materiality') || !(route.name.includes('tasks') || route.name.includes('report'))
})

const contextualHelpClass = computed(() => {
  return [showContextualHelpBar.value || showDisclosureHelpBar.value ? '2xl:w-1/5 w-3/12 h-full' : 'w-0 h-0']
})

const showFFDialog = ref(false)
const { shift_c_g } = useMagicKeys()

whenever(shift_c_g, () => {
  showFFDialog.value = true
})

const { waiting: draftsWaiting, ready: draftsReady, init: initDrafts } = useDisclosureDraftBatches()

watch(
  () => userStore.loggedIn,
  (loggedIn) => {
    if (!loggedIn) return
    initDrafts()
  },
  { immediate: true },
)

function onRouteChange() {
  if (window.HubSpot && window.HubSpotConversations) {
    window.HubSpotConversations.widget.refresh()
  }
}

watch(() => route.fullPath, onRouteChange)

onMounted(() => {
  const script = document.createElement('script')
  script.id = 'hs-script-loader'
  script.src = 'https://js.hs-scripts.com/4879650.js'
  script.defer = true
  script.async = true
  document.body.appendChild(script)
})
</script>

<template>
  <LoadingIndicator v-if="userStore.loggedIn && !finishedLoading"> {{ loadingMessage }}</LoadingIndicator>
  <template v-else>
    <Dialog v-model="showFFDialog" no-footer>
      <FeatureFlags />
    </Dialog>
    <Dialog
      v-if="
        userStore.loggedIn && !userStore.user.is_external && (!userStore.user.is_onboarded || userStore.showOnboarding)
      "
      no-header
      no-footer
      bgColor="bg-blue-800 border-blue-800"
      textColor="text-white"
      width="807px"
      height="750px"
    >
      <Onboarding />
    </Dialog>

    <DynamicDialog />
    <CGToast />

    <AppSidebar v-if="showExtras" />
    <SnackBar v-for="snack in snackData" :key="snack.id" v-bind="snack" />

    <main
      class="w-full h-full bg-white dark:bg-black text-black dark:text-white"
      :class="{ 'pl-sidebar': showExtras, '2xl:pr-16 pr-20': showContextualHelpBar || showDisclosureHelpBar }"
    >
      <div
        :class="`relative ${
          showContextualHelpBar || showDisclosureHelpBar ? '2xl:w-4/5 w-9/12' : 'w-full'
        } h-full overflow-y-auto overflow-x-hidden bg-white dark:bg-black text-black dark:text-white transition-[width] transition-slowest ease`"
      >
        <RouterView />
      </div>
    </main>
    <div
      v-if="userStore.loggedIn && !isNonAuthRoute(route.path)"
      class="fixed top-4 z-20 transition-[width] bg-white dark:bg-black text-black dark:text-white right-16"
    >
      <AddTask :show-button="isTaskPage" :key-args="{}" />
    </div>
    <TaskDetail v-if="userStore.loggedIn && !isNonAuthRoute(route.path)" />
    <div
      v-if="showExtras"
      :class="contextualHelpClass"
      class="fixed top-0 right-0 z-9 transition-[width] bg-white dark:bg-black text-black dark:text-white"
    >
      <ContextualHelpSidebar />
    </div>
    <div
      v-if="showExtras && showDisclosureHelpBar"
      :class="contextualHelpClass"
      class="fixed top-0 right-0 z-10 transition-[width] bg-white dark:bg-black text-black dark:text-white"
    >
      <DisclosureHelpSidebar />
    </div>

    <OngoingProcessIndicator
      v-if="userStore.loggedIn && (draftsWaiting || draftsReady)"
      :status="draftsWaiting ? 'ongoing' : 'success'"
      @click="$router.push('/report/materiality/stakeholders?export')"
      class="cursor-pointer"
    />
  </template>
</template>
